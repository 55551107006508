// Bootstrap
@import "base/bootstrap-grid.scss";
@import "bootstrap/scss/bootstrap-grid.scss";

/* @import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/utilities/api";
@import "bootstrap/scss/helpers/visually-hidden"; */

// Base
@import "base/fonts.scss";
@import 'base/variables.scss';
@import "base/html.scss";

// import Swiper and modules styles
@import 'swiper/css/bundle';

// Custom
// @import "components/animation.scss";
@import "components/button.scss";
@import "components/logo.scss";
@import "components/animation.scss";
@import "components/transition.scss";
@import "components/image.scss";
@import "components/video.scss";
@import "components/footer.scss";
@import "components/hero.scss";
@import "components/content.scss";
@import "components/navigation.scss";
@import "components/sidenavigation.scss";
@import "components/stepnavigation.scss";
@import "components/sideteaser.scss";
@import "components/accordion.scss";
@import "components/table.scss";
@import "components/counter.scss";
@import "components/teaser.scss";
@import "components/slider.scss";