.hero {
    position: relative;
    height: 100vh;
    display: grid;
    place-items: center;
    padding: 20px;
    background-color: var(--bg-dark);
  }
  
  .hero-image {
    position: absolute;
    inset: 0;
    overflow: hidden;
    z-index: 0;
  
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .hero-video {
    position: absolute;
    inset: 0;
    overflow: hidden;
    z-index: 0;
  
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  
  .hero-title {
    position: relative;
    @include fluid-type($min_width, $max_width, 40px, 86px);
    color: white;
    z-index: 10;
    font-weight: 600;
    line-height: 0.9em; 
    text-align: center;
    pointer-events: none;
  
    &[data-shadow="text"] {
      text-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
    }
    span {
      &.smaller {
        @include fluid-type($min_width, $max_width, 26px, 38px);
      }
    }
    @include media-breakpoint-down(sm) {
      line-height: 1em;
    }
  }
  
  .hero-detail {
    position: relative;
    background-color: var(--bg-dark);
    width: 100%;
    height: 100%;
    max-height: 800px;
    aspect-ratio: 3/2;
  
    &[data-size="full"] {
      height: 100vh;
      max-height: initial;
      aspect-ratio: initial;
  
      @include media-breakpoint-down(lg) {
        height: initial;
        aspect-ratio: 5/3;
      }
    }
  
    .image {
      position: absolute;
      inset: 0;
      overflow: hidden;
      z-index: 0;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  